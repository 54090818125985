import react from "react";
import "./App.css";

import { PDFDownloadLink, BlobProvider, usePDF } from "@react-pdf/renderer";

import InputPage from "./inputRenderer/mainPage";
import PDFPage from "./pdfRenderer/mainPage";
import PDFPreViewer from "./pdfPreViewer";
import tmpData from "./tmpData";
import axios from "axios";
import * as pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  react.useEffect(() => {
    const originalConsole = { ...console };

    // console.log = () => {};
    // console.error = () => {};
    // console.warn = () => {};
    // console.info = () => {};
    // console.debug = () => {};

    // return () => {
    //   Object.assign(console, originalConsole);
    // };
  }, []);

  const [data, setData] = react.useState(tmpData);
  const [pdfComponent, setPdfComponent] = react.useState(
    <PDFPage data={data} />
  );
  const [instance, updateInstance] = usePDF({
    document: pdfComponent,
  });

  const canvasRef = react.useRef(null);

  const renderCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const renderPDF = async () => {
      if (instance.url) {
        const reader = new FileReader();

        try {
          fetch(instance.url, {
            headers: {
              "Content-Type": "application/pdf",
              Accept: "application/pdf",
            },
          })
            .then(async (response) => {
              const file = await response.blob();

              if (file) {
                reader.onload = async (event) => {
                  const pdfData = event.target.result;
                  const loadingTask = pdfjs.getDocument({ data: pdfData });
                  const pdf = await loadingTask.promise;
                  const page = await pdf.getPage(1);
                  const viewport = page.getViewport({ scale: 1 });

                  canvas.height = viewport.height;
                  canvas.width = viewport.width;

                  const renderContext = {
                    canvasContext: context,
                    viewport,
                  };

                  await page.render(renderContext);
                };

                reader.readAsArrayBuffer(file);
              }
            })
            .catch((e) => {});
        } catch (e) {}
      }
    };

    renderPDF();
  };

  let timeoutId = 0;

  react.useEffect(() => {
    setPdfComponent(<PDFPage data={data} />);
  }, [data]);

  react.useEffect(() => {
    updateInstance({
      document: pdfComponent,
    });
  }, [pdfComponent]);

  react.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      renderCanvas();
      timeoutId = undefined;
    }, 1000);
  }, [instance]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      }}
    >
      <h1>이력서쓰자....</h1>

      <button
        onClick={() => {
          console.error("error!");
        }}
      >
        efwef
      </button>

      <input
        onChange={(event) => {
          setData({
            ...data,
            body: data?.body.map((datum, idx) =>
              idx === 0
                ? {
                    title: event.target.value,
                    type: "text",
                    content: "React.js를 사용하는 프론트엔드 개발자입니다.",
                  }
                : datum
            ),
          });
        }}
      />
      <a href={instance.url} download={`${data?.header?.title}.pdf`}>
        Download
      </a>

      {/* <InputPage data={data} /> */}

      <PDFPreViewer canvasRef={canvasRef} />
    </div>
  );
}

export default App;
