const PDFViewer = ({ canvasRef }) => {
  return (
    <canvas
      id="pdf-canvas"
      ref={canvasRef}
      style={{
        width: "500px",
        border: "1px solid #ccc",
        borderRadius: "1rem",
      }}
    />
  );
};

export default PDFViewer;
