import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.error = function () {};
window.onerror = function (message, source, lineno, colno, error) {};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
