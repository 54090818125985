import styled from "@emotion/styled";

const color = {
  white: "#fff",
  primary: "#003FC7",
  gray: "#676A6F",
  lightGray: "#E2E6EF",
};

const DefaultInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 0.2rem 1rem;

  ::placeholder {
    color: #ccc;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #ccc;
  }

  ::-ms-input-placeholder {
    color: #ccc;
  }
`;

const Page = styled.div`
  // border: 1px solid #000;
  // border-radius: 1rem;

  font-size: 1rem;
  line-height: 1.4;

  padding: 1em;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  line-height: 1.2;
  width: 100%;

  gap: 0.2rem;
`;

const UserName = styled(DefaultInput)`
  font-size: 2rem;
  font-weight: 300;

  width: 18rem;

  @media (min-width: 800px) {
    width: 35vw;
  }
`;

const Position = styled(DefaultInput)`
  color: ${color.primary};
  font-size: 1rem;
  font-weight: 200;

  width: 16rem;

  border-radius: 0.6rem;

  @media (min-width: 800px) {
    width: 30vw;
  }
`;

export { Page, Header, UserName, Position };
