import { Text, StyleSheet } from "@react-pdf/renderer";
import { styles, color } from "../pdfRenderer/styles";

const longTypeStyles = StyleSheet.create({
  sectionContentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  sectionContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: 10,
  },
  sectionMain: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    flexBasis: 0,
    flexGrow: 1,
  },
  contentTitle: { fontSize: 12, fontWeight: 500 },
  sectionItems: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    flexGrow: 1,

    lineHeight: 1.4,
  },
  sectionItem: { wordBreak: "keep-all" },
  sectionPositionText: { color: color.gray, fontSize: 9, fontWeight: 300 },
  techStackList: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  techStackItem: {
    backgroundColor: color.lightGray,
    lineHeight: 1.4,
    fontWeight: 300,

    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
  },

  sectionSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    flexShrink: 0,

    fontWeight: 300,
  },
  dateText: {
    color: color.gray,
  },
});

const LongType = ({ data }) => {
  return (
    data.content && (
      <div style={longTypeStyles.sectionContentWrapper}>
        {data.content.map((content, contentIdx) => (
          <div key={contentIdx} style={longTypeStyles.sectionContent}>
            <div style={longTypeStyles.sectionMain}>
              <Text style={longTypeStyles.contentTitle}>{content.title}</Text>
              <Text style={longTypeStyles.sectionPositionText}>
                {content.position}
              </Text>
              {content.stacks && (
                <div style={longTypeStyles.techStackList}>
                  {content.stacks.map((stackItem, itemIdx) => (
                    <Text key={itemIdx} style={longTypeStyles.techStackItem}>
                      {stackItem}
                    </Text>
                  ))}
                </div>
              )}
              {content.items && (
                <div style={longTypeStyles.sectionItems}>
                  {content.items.map((item, itemIdx) => (
                    <Text key={itemIdx} style={longTypeStyles.sectionItem}>
                      • {item}
                    </Text>
                  ))}
                </div>
              )}
            </div>

            <div style={longTypeStyles.sectionSide}>
              <Text style={styles.locationText}>{content.location}</Text>
              <Text style={longTypeStyles.dateText}>
                {content.start} - {content.end}
              </Text>
            </div>
          </div>
        ))}
      </div>
    )
  );
};

export default LongType;
