import { Page, Text, View, Document, Font } from "@react-pdf/renderer";
import { styles } from "./styles";
import SectionRenderer from "./SectionRenderer";

Font.register({
  family: "Pretendard-PDF",
  fonts: [
    { src: require("../fonts/Pretendard-Thin.ttf"), fontWeight: 100 },
    { src: require("../fonts/Pretendard-ExtraLight.ttf"), fontWeight: 200 },
    { src: require("../fonts/Pretendard-Light.ttf"), fontWeight: 300 },
    { src: require("../fonts/Pretendard-Regular.ttf"), fontWeight: 400 },
    { src: require("../fonts/Pretendard-Medium.ttf"), fontWeight: 500 },
    { src: require("../fonts/Pretendard-SemiBold.ttf"), fontWeight: 600 },
  ],
});

const PDFPage = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <div style={styles.pageWrapper}>
          <div style={styles.header}>
            <Text style={styles.userName}>{data?.header?.name}</Text>
            <Text style={styles.positionText}>{data?.header?.position}</Text>
            <div style={styles.contactSection}>
              <Text style={styles.contactText}>{data?.header?.phone}</Text>
              <Text style={styles.contactText}>|</Text>
              <Text style={styles.contactText}>{data?.header?.email}</Text>
              <Text style={styles.contactText}>|</Text>
              <Text style={styles.contactText}>{data?.header?.homepage}</Text>
              <Text style={styles.contactText}>|</Text>
              <Text style={styles.contactText}>{data?.header?.github}</Text>
            </div>
          </div>
          <div style={styles.sections}>
            {data &&
              data.body &&
              data.body.map((datum, datumIdx) => (
                <SectionRenderer key={datumIdx} data={datum} />
              ))}
          </div>
        </div>

        <View style={styles.footer} fixed>
          <Text style={styles.pageNumber}>resume</Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

export default PDFPage;
