import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { color } from "../pdfRenderer/styles";

import TextType from "./TextType";
import LongType from "./LongType";
import ShortType from "./shortType";

const rendererStyles = StyleSheet.create({
  section: {
    width: "100%",
  },
  sectionHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
  },
  highlightText: { color: color.primary },
  contentTitle: {
    fontSize: 12,
    fontWeight: 500,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  sectionDivBar: {
    borderBottom: "1px solid #000",
    flexGrow: 1,
  },
});

const SectionRenderer = ({ data }) => {
  return (
    <View style={rendererStyles.section} wrap={false}>
      <div style={rendererStyles.sectionHeader}>
        <Text style={rendererStyles.sectionTitle}>
          <Text style={rendererStyles.highlightText}>
            {data.title.slice(0, 3)}
          </Text>
          {data.title.slice(3)}
        </Text>
        <div style={rendererStyles.sectionDivBar} />
      </div>

      {data.type === "text" && <TextType data={data} />}
      {data.type === "short" && <ShortType data={data} />}
      {data.type === "long" && <LongType data={data} />}
    </View>
  );
};

export default SectionRenderer;
